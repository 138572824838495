<template>
  <div class="Contact">
    <a-row :gutter="8">
      <a-col :span="12">
        <a-button
          icon="wechat"
          style="background-color: #04be02; color: white"
          size="large"
          block
          @click="showWechat"
        >
          加我微信
        </a-button>
      </a-col>
      <a-col :span="12">
        <a :href="'tel://'+phone">
          <a-button type="primary" icon="phone" size="large" @click="callPhone" block>
            拨打电话
          </a-button>
        </a>
      </a-col>
    </a-row>
    <a-modal v-model="ifShowWechat" title="长按扫码加我微信" :footer="null">
      <img :src="wechatSrc" style="width: 100%; height: auto;"/>
    </a-modal>
  </div>
</template>

<script>
import data from "@/data.js"
export default {
  name: "Contact",
  props: {
    pageName: String,
    id: String
  },
  data() {
    return {
      phone: data.phone,
      wechatSrc: data.wechatSrc,
      ifShowWechat: false
    }
  },
  methods: {
    showWechat() {
      this.ifShowWechat = true
      this.$pushCNZZ.event(
        '联系我',
        this.pageName+'页加我微信',
        this.id,
        this.value,
        this.nodeId
      );
    },
    callPhone() {
      this.$pushCNZZ.event(
        '联系我',
        this.pageName+'页拨打电话',
        this.id,
        this.value,
        this.nodeId
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>