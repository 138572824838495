<template>
  <div class="Home">
    <div class="main">
      <div style="margin-bottom: 16px">
        <a-row type="flex" align="middle">
          <a-avatar :size="64" style="color: white; backgroundColor: #87d068; font-size: 26px;">
            J
          </a-avatar>
          <div style="margin-left: 16px">
            <label style="font-size: 24px"
              ><b>{{ cnName }} {{ enName }}</b></label
            >
            <br />
            <label>{{ summary }}</label>
          </div>
        </a-row>
      </div>
      <div style="margin-top: 16px">
        <h2><label>个人介绍</label></h2>
        <div>{{ description }}</div>
      </div>
      <div style="margin-top: 16px">
        <h2><label>一些资料</label></h2>
        <div>
          <a-list item-layout="horizontal" :data-source="list">
            <a-list-item slot="renderItem" slot-scope="item" @click="goDocument(item.id)">
              <a-list-item-meta>
                <div slot="title">{{ item.title }}</div>
                <a-avatar
                  slot="avatar"
                  shape="square"
                  :size="48"
                  :icon="item.icon"
                />
                <div slot="description">{{ item.title }}</div>
              </a-list-item-meta>
              <div><a-icon type="right-circle" /></div>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
    <div class="footer">
      <Contact pageName="Home" id=""/>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue"
import data from "@/data.js"
export default {
  name: "Home",
  components: {
    Contact
  },
  methods: {
    goDocument(id) {
      this.$pushCNZZ.event(
        '文档',
        'Home页点击查看',
        id
      );
      this.$router.push({
        path: "/document/"+id
      });
    }
  },
  data() {
    return data
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  padding: 16px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px;
  /* 兼容iPhoneX */
	padding-bottom: 30px;
  background-color: #ededed;
}
</style>
